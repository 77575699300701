import { z } from 'zod'

export const getResponseSchema = (formHandle: string) =>
  z.object({
    [`save_${formHandle}_Submission`]: z.object({
      id: z.string(),
      isSpam: z.boolean(),
      spamReason: z.string().nullable(),
    }),
  })

export const getMutationEntryResponseSchema = (handle: string, entryType = 'default') =>
  z.object({
    [`save_${handle}_${entryType}_Entry`]: z.object({ id: z.string() }),
  })
