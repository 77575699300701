import { useId } from 'react'
import { z } from 'zod'

import { AutoFormInputComponentProps, getBaseSchema } from '../ui/auto-form'
import { FormControl, FormDescription, FormItem } from '../ui/form'
import { FormFieldWrapper } from './FormFieldWrapper'
import { FormSelect } from './FormSelect'

export function AutoFormSelect({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
  zodItem,
}: AutoFormInputComponentProps) {
  const fallBackName = useId()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const name = fieldProps.name ?? fallBackName

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  let values: string[] = (getBaseSchema(zodItem) as unknown as z.ZodEnum<[string, ...[string]]>)._def.values

  if (!Array.isArray(values)) {
    values = Object.values(values)
  }

  return (
    <FormItem>
      <FormControl>
        <FormFieldWrapper
          type={fieldConfigItem.fieldType ?? 'text'}
          label={label}
          required={isRequired}
          {...fieldProps}
          name={name}
        >
          <FormSelect label={label} required={isRequired} field={field} {...fieldProps} name={name} values={values} />
        </FormFieldWrapper>
      </FormControl>
      {fieldConfigItem.description && <FormDescription>{fieldConfigItem.description}</FormDescription>}
    </FormItem>
  )
}
