import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { twJoin, twMerge } from 'tailwind-merge'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import { useFormTheme } from './CmsForm'

type InputProps = InputHTMLAttributes<HTMLDivElement> & {
  label?: string
  name: string
  required: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<FieldValues, any>
  values: string[]
}

function SelectField({ field, values, ...props }: InputProps, ref: ForwardedRef<HTMLDivElement>) {
  const theme = useFormTheme()
  return (
    <div ref={ref}>
      <Select onValueChange={field.onChange} defaultValue={field.value}>
        <SelectTrigger
          className={twMerge(
            'peer flex-1 border-0 bg-transparent px-px py-0 pl-0 font-body text-sm font-medium text-current invalid:text-blue-500 focus:ring-0 focus-visible:outline-none',
            theme === 'dark' ? 'text-white' : 'text-neutral-850',
            props.readOnly && 'pointer-events-none select-none',
          )}
        >
          <SelectValue>{field.value ?? 'Select an option'}</SelectValue>
        </SelectTrigger>
        <SelectContent
          className={twMerge(
            'peer flex-1 border-0 px-px py-0 pl-0 font-body text-sm font-medium text-current invalid:text-blue-500 focus:ring-0 focus-visible:outline-none',
            theme === 'dark' ? 'bg-neutral-850 text-white' : 'bg-white text-neutral-850',
            props.readOnly && 'pointer-events-none select-none',
          )}
        >
          {values.map((value: string) => (
            <SelectItem
              value={value}
              key={value}
              className={twJoin(theme === 'dark' && 'focus:bg-neutral-600 focus:text-white')}
            >
              {value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export const FormSelect = forwardRef<HTMLDivElement, InputProps>(SelectField)
