import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { Textarea } from '../ui/textarea'
import { useFormTheme } from './CmsForm'

type InputProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  name: string
  required: boolean
}

function TextareaField(
  { label, required, name, value, defaultValue, ...props }: InputProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const theme = useFormTheme()
  return (
    <Textarea
      ref={ref}
      id={name}
      required={required}
      placeholder={label}
      defaultValue={defaultValue}
      value={value}
      className={twMerge(
        'peer flex-1 border-0 bg-transparent px-px py-0 text-sm font-medium text-current placeholder:text-white/0 autofill:bg-transparent autofill:font-body invalid:text-blue-500 focus:ring-0 focus-visible:outline-none',
        theme === 'dark' ? 'autofill:forced-dark' : 'autofill:forced-light',
        props.readOnly && 'pointer-events-none select-none',
      )}
      {...props}
    ></Textarea>
  )
}

export const FormTextarea = forwardRef<HTMLTextAreaElement, InputProps>(TextareaField)
