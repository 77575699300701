/* eslint-disable @typescript-eslint/no-explicit-any */

import { InputHTMLAttributes } from 'react'
import { z, ZodRawShape, ZodTypeAny } from 'zod'

import type { GetForm } from '@/data/getForm'
import { AutoFormInputComponentProps, FieldConfig, FieldConfigItem } from '../ui/auto-form'
import { AutoFormCheckbox } from './AutoFormCheckbox'
import { AutoFormInput } from './AutoFormInput'
import { AutoFormSelect } from './AutoFormSelect'
import { AutoFormTextarea } from './AutoFormTextarea'
import { createMutation } from './createFormMutation'

type AutoFormParts = {
  zodRaw: ZodRawShape
  config: FieldConfig<z.infer<z.ZodObject<any, any>>>
  defaultValues: Record<string, any>
}

// Looks weird, but render nothing, and the form handles the value.
// Warning - no DOM element as a result.
const HiddenField = (_: AutoFormInputComponentProps) => null

const convertVerbType = (verbType: string): FieldConfigItem['fieldType'] => {
  switch (verbType) {
    case 'Hidden':
      return HiddenField
    case 'Email':
      return AutoFormInput
    case 'MultiLineText':
      return AutoFormTextarea
    case 'SingleLineText':
      return AutoFormInput
    case 'Agree':
      return AutoFormCheckbox
    case 'Dropdown':
      return AutoFormSelect
  }
  return undefined
}

const convertVerbToInputType = (verbType: string): InputHTMLAttributes<HTMLInputElement>['type'] => {
  switch (verbType) {
    case 'Email':
      return 'email'
  }
  return undefined
}

const reduceRow = (rowAcc: AutoFormParts, rowField: GetForm['pages'][0]['rows'][0]['rowFields'][0]) => {
  let zodRaw: ZodTypeAny
  if (rowField.typeName === 'Field_Dropdown' && rowField.options) {
    zodRaw = z.enum(
      [
        ...(rowField.options.map((keyVal) => {
          return keyVal.label
        }) as [string, ...string[]]),
      ],
      {
        required_error: rowField.required && rowField.errorMessage ? rowField.errorMessage : undefined,
      },
    )
  } else {
    if (rowField.displayName === 'Email') {
      zodRaw = z
        .string({ required_error: rowField.required && rowField.errorMessage ? rowField.errorMessage : undefined })
        .email()
    } else if (rowField.displayName === 'Agree') {
      zodRaw = z.boolean({
        required_error: rowField.required && rowField.errorMessage ? rowField.errorMessage : undefined,
      })
      if (!rowField.required) {
        zodRaw = z.optional(zodRaw)
      }
    } else {
      zodRaw = z.string({
        required_error: rowField.required && rowField.errorMessage ? rowField.errorMessage : undefined,
      })
    }
    // Default values remove the required flag, so use defaultValues object instead (kept for reference)
    // if (rowField.defaultValue) {
    //   zodRaw = zodRaw.default(rowField.defaultValue)
    // }
  }
  if (!rowField.required) {
    zodRaw = z.optional(zodRaw)
  }
  if (rowField.label) {
    zodRaw = zodRaw.describe(rowField.label)
  }

  const defaultValue = rowField.typeName === 'Field_Agree' ? rowField.defaultState : rowField.defaultValue ?? undefined

  return {
    zodRaw: { ...rowAcc.zodRaw, [rowField.handle]: zodRaw },
    config: {
      ...rowAcc.config,
      [rowField.handle]: {
        inputProps: {
          type: convertVerbToInputType(rowField.displayName),
          placeholder:
            rowField.typeName === 'Field_Agree'
              ? rowField.descriptionHtml ?? ''
              : rowField.placeholder ?? rowField.label,
          required: rowField.required ?? false,
        },
        fieldType: convertVerbType(rowField.displayName),
        ...(rowField.typeName === 'Field_Agree'
          ? {
              checkedValue: rowField.checkedValue,
              uncheckedValue: rowField.uncheckedValue,
            }
          : {}),
      },
    },
    defaultValues: {
      ...rowAcc.defaultValues,
      [rowField.handle]: defaultValue,
    },
  }
}

export const convertGqlToForm = (form: GetForm | undefined) => {
  if (!form) {
    return {}
  }
  const { zodRaw, config, defaultValues } = form.pages[0].rows.reduce(
    (acc, row) => {
      const rowFields: AutoFormParts = row.rowFields.reduce<AutoFormParts>(reduceRow, {
        zodRaw: {},
        config: {},
        defaultValues: {},
      } as AutoFormParts)
      return {
        zodRaw: {
          ...acc.zodRaw,
          ...rowFields.zodRaw,
        },
        config: {
          ...acc.config,
          ...rowFields.config,
        },
        defaultValues: {
          ...acc.defaultValues,
          ...rowFields.defaultValues,
        },
      }
    },
    { zodRaw: {}, config: {}, defaultValues: {} } as AutoFormParts,
  )
  const formSchema = z.object(zodRaw)
  const mutation = createMutation(form)
  return { formSchema, config, mutation, defaultValues }
}
