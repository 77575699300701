import * as R from 'ramda'

import { GetForm } from '@/data/getForm'
import { recaptchaEnabled } from './recaptchaEnabled'

export type IForm = GetForm
export type IPage = IForm['pages'][0]
export type IRow = IPage['rows'][0]
export type IField = IRow['rowFields'][0]

export interface IFormKeys {
  handle: string
  type: string
}

function getFormFieldMeta(input: IForm): IFormKeys[] {
  const { pages } = input

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rows = R.pipe<any, IRow[][], IField[][], IFormKeys[]>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    R.pluck('rows') as any,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    R.chain(R.pluck('rowFields')) as any,
    R.chain(R.map(({ handle, type }) => ({ handle, type }))),
  )(pages)

  return rows
}

export function createMutationHandle({ handle }: IForm): string {
  return `save_${handle}_Submission`
}

interface IGetInputTypes {
  handle: string
  type: string
  formHandle: string
}

function getInputType({ type, handle, formHandle }: IGetInputTypes): string {
  return (
    {
      'verbb\\formie\\fields\\formfields\\Name': `${formHandle}_${handle}_FormieNameInput`,
      'verbb\\formie\\fields\\formfields\\Address': `${formHandle}_${handle}_FormieAddressInput`,
      'verbb\\formie\\fields\\formfields\\Repeater': `${formHandle}_${handle}_FormieRepeaterInput`,
      'verbb\\formie\\fields\\formfields\\Group': `${formHandle}_${handle}_FormieGroupInput`,
      'verbb\\formie\\fields\\formfields\\Checkboxes': `[String]`,
      'verbb\\formie\\fields\\formfields\\Number': `Number`,
    }[type] || 'String'
  )
}

const join = R.join(', ')

function createMutationTypes(input: IForm): string {
  return R.pipe(
    getFormFieldMeta,
    R.map(
      ({ handle, type }) =>
        `$${handle}: ${getInputType({
          type,
          handle,
          formHandle: input.handle,
        })}`,
    ),
    join,
  )(input)
}

function createMutationValues(input: IForm): string {
  const keys = R.pipe(
    getFormFieldMeta,
    R.pluck('handle'),
    R.map((key) => `${key}: $${key}`),
    join,
  )(input)

  return keys
}

export function createMutation(form: IForm): string {
  const isRecaptchaEnabled = recaptchaEnabled(form)
  const mutationTypes = `${createMutationTypes(form)}${
    isRecaptchaEnabled ? `, $recaptchaCaptcha: FormieCaptchaInput` : ''
  }`
  const mutationHandle = createMutationHandle(form)
  const mutationValues = `${createMutationValues(form)}${
    isRecaptchaEnabled ? `, recaptchaCaptcha: $recaptchaCaptcha` : ''
  }`
  return `mutation FormMutation(${mutationTypes}) {
  ${mutationHandle}(${mutationValues}) {
    id
    spamReason
    isSpam
  }
}`
}
