import { ReactNode } from 'react'
import ArrowLeftIcon from '@heroicons/react/20/solid/ArrowLeftIcon'
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from './Button'

type ActionLinkProps = ButtonProps & {
  arrow?: 'left' | 'right' | false | Exclude<ReactNode, string>
  collapse?: boolean
  underline?: boolean
}

export function ActionLink({ arrow = 'right', children, underline, className, ...props }: ActionLinkProps) {
  // This logic is gnarly. Basically you can override the arrow, by passing in a new arrow (TSX), or left / right (default arrows) or false (no arrow).
  // If you supply your own TSX, you must specify appropriate padding in className.
  const icon = arrow ? (
    Boolean(arrow) && typeof arrow === 'string' ? (
      arrow === 'left' ? (
        <ArrowLeftIcon className="absolute left-0 aspect-square w-[1.5em] text-current transition-transform group-hover/link:-translate-x-1" />
      ) : (
        <ArrowRightIcon className="absolute right-0 aspect-square w-[1.5em] text-current transition-transform group-hover/link:translate-x-1" />
      )
    ) : (
      arrow
    )
  ) : undefined

  return (
    <Button
      icon={icon}
      className={twMerge(
        'group/link relative font-sans text-sm font-semibold uppercase tracking-widest text-current',
        arrow === 'left' && 'pl-7',
        arrow === 'right' && 'pr-7',
        className,
      )}
      {...props}
    >
      {underline ? <span className="border-b-2 border-b-current pb-1">{children}</span> : children}
    </Button>
  )
}
