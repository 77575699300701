'use client'

import { useCallback, useState } from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import dynamic from 'next/dynamic'
import { twJoin, twMerge } from 'tailwind-merge'
import { z } from 'zod'

import { GetForm } from '@/data/getForm'
import { getResponseSchema } from '@/data/saveFormDataSchema'
import { Theme } from '@/data/shared'
import { createContext } from '@/utils/createContext'
import { ActionLink } from '../ActionLink'
import { FieldConfig } from '../ui/auto-form'
import { CmsFormCaptcha } from './CmsFormCaptcha'
import { convertGqlToForm } from './convertGqlToForm'
import { recaptchaEnabled } from './recaptchaEnabled'

export interface CmsFormProps {
  className?: string
  theme?: Theme
  formStructure: GetForm
  formHandle: string
  defaultValues?: Record<string, unknown>
}

const AutoForm = dynamic(() => import('../ui/auto-form'), {
  ssr: false,
})

type FieldConfigCheckbox = FieldConfig<{ checkedValue: string; uncheckedValue: string }>

export const [useFormTheme, FormThemeProvider] = createContext<Theme>()

export function CmsForm({
  className,
  formStructure,
  defaultValues: overrideDefaultValues = {},
  formHandle,
  theme,
}: CmsFormProps) {
  const { formSchema, config, defaultValues } = convertGqlToForm(formStructure)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [isRecaptchaErrored, setRecaptchaIsErrored] = useState(false)
  const [captchaEnabled, setCaptchaEnabled] = useState(false)
  const [recaptchaCaptcha, setRecaptchaCaptcha] = useState<string>('')
  const [values, setValues] = useState(() => {
    return { ...defaultValues, ...overrideDefaultValues }
  })

  const setAndEnableCaptcha = useCallback(
    (newValues: typeof values) => {
      setValues(newValues)
      // Only enable captcha when values are getting set and is enabled in the form
      if (recaptchaEnabled(formStructure)) {
        setCaptchaEnabled(true)
      }
    },
    [formStructure],
  )

  if (!formStructure || !config || !formSchema) {
    return null
  }

  const variants: Variants = {
    out: { opacity: 0, y: -8 },
    in: {
      opacity: 1,
      y: 0,
    },
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormSubmit = async (data: z.infer<typeof formSchema>) => {
    const checkboxes: Record<string, string> = Object.keys(config)
      .filter(
        (key) =>
          (config[key] as FieldConfigCheckbox).checkedValue && (config[key] as FieldConfigCheckbox).uncheckedValue,
      )
      .reduce((acc, key) => {
        const configVal = config[key] as FieldConfigCheckbox
        return {
          ...acc,
          // false is blank string, not you know, the uncheckedValue 😒
          [key]: data[key] ? configVal.checkedValue : '',
        }
      }, {})
    const cleanedData: Record<string, unknown> = {
      ...data,
      ...checkboxes,
    }
    setIsSubmitting(true)

    const resp = await fetch('/form-post', {
      method: 'POST',
      body: JSON.stringify({
        formHandle,
        data: {
          ...cleanedData,
          ...(recaptchaEnabled(formStructure)
            ? { recaptchaCaptcha: { name: 'g-recaptcha-response', value: recaptchaCaptcha } }
            : {}),
        },
      }),
    })

    if (resp.status === 202) {
      setRecaptchaIsErrored(true)
      return
    }

    if (resp.status !== 200) {
      setIsErrored(true)
      return
    }
    try {
      getResponseSchema(formHandle).parse(await resp.json())
      setIsSubmitted(true)
    } catch (_e: unknown) {
      setIsErrored(true)
    }
  }

  const submitButton = formStructure.pages[0].settings.submitButtonLabel ?? 'Submit'

  const buttonClassName =
    formStructure.pages[0].settings.buttonsPosition === 'center'
      ? 'justify-center'
      : formStructure.pages[0].settings.buttonsPosition === 'left'
        ? 'justify-start'
        : 'justify-end'
  return (
    <div className={twMerge('relative', className)}>
      {captchaEnabled && <CmsFormCaptcha onLoad={setRecaptchaCaptcha} />}
      <AnimatePresence>
        {isErrored ? (
          <div className="font-display">
            An error occurred with submitting your form.
            <br />
            Please refresh and try again.
          </div>
        ) : isSubmitted ? (
          <motion.div
            variants={variants}
            initial="out"
            animate="in"
            exit="out"
            className="absolute inset-0 font-sans text-lg font-semibold"
          >
            <div
              className="font-display"
              dangerouslySetInnerHTML={{
                __html: formStructure.settings.submitActionMessageHtml ?? 'Thanks for you enquiry!',
              }}
            />
          </motion.div>
        ) : (
          <motion.div key="form" variants={variants} initial="in" animate="in" exit="out" className="relative w-full">
            <FormThemeProvider value={theme ?? 'light'}>
              <AutoForm
                className="flex flex-col"
                innerClassName="space-y-4"
                // Pass the schema to the form
                formSchema={formSchema}
                // You can add additional config for each field
                // to customise the UI
                fieldConfig={config}
                values={values}
                onValuesChange={setAndEnableCaptcha}
                onSubmit={onFormSubmit}
              >
                <div className={twJoin('flex', buttonClassName)}>
                  {isRecaptchaErrored ? (
                    <ActionLink onClick={() => document.location.reload()}>Reload Page</ActionLink>
                  ) : (
                    <ActionLink type="submit" disabled={isSubmitting || (recaptchaCaptcha === '' && captchaEnabled)}>
                      {isSubmitting === true ? 'Submitting' : submitButton}
                    </ActionLink>
                  )}
                </div>
                {isRecaptchaErrored && (
                  <div className="text-red-500">
                    There was an error with the recaptcha, please reload or try another browser.
                  </div>
                )}
              </AutoForm>
            </FormThemeProvider>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
