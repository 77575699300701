import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { useFormTheme } from './CmsForm'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  name: string
  required: boolean
}

function InputField({ label, required, name, type, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement>) {
  const theme = useFormTheme()
  return (
    <input
      ref={ref}
      id={name}
      required={required}
      placeholder={label}
      className={twMerge(
        'peer h-7 flex-1 border-0 bg-transparent px-px py-0 text-sm font-medium text-current placeholder:text-white/0 autofill:bg-transparent autofill:font-body focus:ring-0 focus-visible:outline-none',
        theme === 'dark' ? 'autofill:forced-dark invalid:text-blue-500' : 'autofill:forced-light invalid:text-blue-500',
        props.readOnly && 'pointer-events-none select-none',
      )}
      type={type ?? 'text'}
      {...props}
    />
  )
}

export const FormInput = forwardRef<HTMLInputElement, InputProps>(InputField)
