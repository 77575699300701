/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useId } from 'react'

import { cn } from '@/lib/utils'
import { AutoFormInputComponentProps } from '../ui/auto-form'
import { Checkbox } from '../ui/checkbox'
import { FormControl, FormItem, FormLabel } from '../ui/form'

export function AutoFormCheckbox({ label, isRequired, fieldProps }: AutoFormInputComponentProps) {
  const fallBackName = useId()
  const name = fieldProps.name ?? fallBackName
  return (
    <FormItem className="relative flex min-h-[28px] flex-row items-end space-x-3 space-y-0">
      <div className="flex h-full items-end">
        <label
          htmlFor={name}
          className="pointer-events-none absolute left-0 right-0 box-content flex h-6 flex-col items-stretch justify-center transition-opacity"
        >
          <span
            className={cn(
              'pointer-events-none absolute top-1 h-4 transition-all',
              'text-2xs font-semibold opacity-50 -translate-y-full peer-placeholder-shown:text-base peer-placeholder-shown:font-medium peer-placeholder-shown:opacity-70 peer-placeholder-shown:translate-y-0 peer-autofill:!font-body peer-autofill:text-2xs peer-autofill:font-semibold peer-autofill:-translate-y-full peer-focus:text-2xs peer-focus:font-semibold peer-focus:opacity-60 peer-focus:-translate-y-full',
            )}
          >
            {label} {isRequired && <span className="text-destructive"> *</span>}
          </span>
        </label>
        <div className="-mb-1">
          <FormControl>
            <Checkbox checked={fieldProps.value} onCheckedChange={fieldProps.onChange} {...fieldProps} type="button" />
          </FormControl>
        </div>
      </div>
      <div className="space-y-1 leading-none">
        <FormLabel dangerouslySetInnerHTML={{ __html: fieldProps.placeholder }}></FormLabel>
      </div>
    </FormItem>
  )
}
