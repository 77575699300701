import { ForwardedRef, forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldErrors } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { Theme } from '@/data/shared'

type FormFieldProps = {
  label?: string
  required: boolean
  theme?: Theme
  errors: FieldErrors
  children: React.ReactNode
  className?: string
  readOnly?: boolean
  name: string
}

function InnerFormFieldWrapper(
  { className, label, errors, required, children, readOnly, name }: FormFieldProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div ref={ref}>
      <label
        htmlFor={name}
        className={twMerge(
          'relative box-content flex h-7 flex-col items-stretch justify-center border-b border-b-current transition-opacity',
          readOnly && 'pointer-events-none',
          className,
        )}
      >
        {children}
        <span
          className={twMerge(
            'pointer-events-none absolute top-1 h-4 transition-all',
            'text-2xs font-semibold opacity-50 -translate-y-full peer-placeholder-shown:text-base peer-placeholder-shown:font-medium peer-placeholder-shown:opacity-70 peer-placeholder-shown:translate-y-0 peer-autofill:!font-body peer-autofill:text-2xs peer-autofill:font-semibold peer-autofill:-translate-y-full peer-focus:text-2xs peer-focus:font-semibold peer-focus:opacity-60 peer-focus:-translate-y-3/4',
          )}
        >
          {label} {required && <span className="text-destructive"> *</span>}
        </span>
      </label>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <span className="mb-2 text-2xs text-red-500">{message}</span>}
      />
    </div>
  )
}

export const FormFieldWrapper = forwardRef<HTMLDivElement, FormFieldProps>(InnerFormFieldWrapper)
