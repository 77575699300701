import { useId } from 'react'

import { AutoFormInputComponentProps } from '../ui/auto-form'
import { FormControl, FormDescription, FormItem } from '../ui/form'
import { FormFieldWrapper } from './FormFieldWrapper'
import { FormInput } from './FormInput'

export function AutoFormInput({ label, isRequired, fieldConfigItem, fieldProps }: AutoFormInputComponentProps) {
  const fallBackName = useId()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const name = fieldProps.name ?? fallBackName

  return (
    <FormItem>
      <FormControl>
        <FormFieldWrapper
          type={fieldConfigItem.fieldType ?? 'text'}
          label={label}
          required={isRequired}
          {...fieldProps}
          name={name}
        >
          <FormInput
            type={fieldConfigItem.fieldType ?? 'text'}
            label={label}
            required={isRequired}
            {...fieldProps}
            name={name}
          />
        </FormFieldWrapper>
      </FormControl>
      {fieldConfigItem.description && <FormDescription>{fieldConfigItem.description}</FormDescription>}
    </FormItem>
  )
}
